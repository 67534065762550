'use client';

import { useEffect, useState } from 'react';

import { useSWRConfig } from 'swr';
import Link from 'next/link';
import { Controller, useForm } from 'react-hook-form';

import { useTranslation } from 'src/i18n/client';
import useUser from 'src/hooks/useUser';
import { EMAIL_REGEX } from 'lib/constants';

import Button from 'src/components/Button';
import FormError from 'src/components/FormError';
import { myAccountUrl } from 'src/services/route-utils';
import toast from 'src/components/TailwindToaster';
import { signIn } from 'next-auth/react';

type Props = {
    locale: string;
    redirect?: string;
    error?: string;
};

export default function LoginForm({ locale, redirect, error }: Props) {
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation(locale, 'common');
    const { cache } = useSWRConfig();
    // here we just check if user is already logged in and redirect to profile
    const { setLoadUser } = useUser({ redirectTo: redirect || myAccountUrl('/rezultate'), redirectIfFound: true });
    const { control, handleSubmit } = useForm({
        defaultValues: {
            username: '',
            password: '',
        },
    });

    useEffect(() => {
        if (error) {
            toast.error(t('login.errorAuth'), { id: 'login.error' });
        }
    }, []);

    const onSubmit = async (data: any) => {
        setLoading(true);

        try {
            const response = await signIn('credentials', {
                ...data,
                callbackUrl: '/',
                redirect: false,
            });
            if (response?.error) {
                throw response;
            }

            (cache as any)?.clear();
            await setLoadUser();
        } catch (error: any) {
            toast.error(t('login.error'), { id: 'login.error' });
            setErrorMsg(error?.message || t('unexpectedError'));
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mx-auto text-center md:w-4/5 xl:w-2/3">
            <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <h1 className="text-5xl lg:text-6xl">{t('existingAccount')}</h1>

                <p className="my-4">{t('login.description')}</p>

                <Controller
                    name="username"
                    control={control}
                    rules={{
                        required: t('login.emailRequired'),
                        pattern: {
                            value: EMAIL_REGEX,
                            message: t('login.emailInvalid'),
                        },
                    }}
                    render={({ field: { value, onChange }, fieldState }) => (
                        <div>
                            <input
                                id="username"
                                type="email"
                                className={fieldState.error ? 'form-error' : ''}
                                placeholder={t('login.emailPlaceholder')}
                                value={value}
                                onChange={onChange}
                                disabled={loading}
                            />
                            <FormError error={fieldState.error} />
                        </div>
                    )}
                />

                <Controller
                    name="password"
                    control={control}
                    rules={{ required: t('login.passwordRequired') }}
                    render={({ field: { value, onChange }, fieldState }) => (
                        <div>
                            <input
                                id="password"
                                type="password"
                                className={fieldState.error ? 'form-error' : ''}
                                placeholder={t('login.passwordPlaceholder')}
                                value={value}
                                disabled={loading}
                                onChange={onChange}
                            />
                            <FormError error={fieldState.error} />
                        </div>
                    )}
                />

                <div className="text-right">
                    <Link className="hover:underline" href={myAccountUrl('/recuperare-parola')}>
                        {t('labels.forgotPassword')}
                    </Link>
                </div>

                <Button variant="primary" type="submit" disabled={loading} loading={loading} className="block">
                    {t('login.button')}
                </Button>
            </form>
        </div>
    );
}
